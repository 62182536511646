		$(function () {
			if ($(".js-modal-video").length) {
				$(".js-modal-video").modalVideo({
					channel: "youtube",
					youtube: {
						controls: 1,
					},
				});
			}
		});

		const swiper = new Swiper(".swiper", {
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			},
			pagination: {
				el: ".swiper-pagination",
			},

		});
